/* src/Registration.css */
.registration-form {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 20px;
  margin: auto;
  max-width: 600px;
}

.registration-form .form-label {
  font-weight: bold;
}

.registration-form .form-control {
  border-radius: 0.25rem;
}

.registration-form .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.registration-form .btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

@media (max-width: 768px) {
  .registration-form .row {
    flex-direction: column;
  }
  .registration-form .col-md-4 {
    margin-bottom: 1rem;
  }
}

.signature-canvas {
  border: solid 0.5px #004085;
  border-radius: 15px;

}
